import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  Modal,
  TextField,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import WebsiteBuilderService from '../../services/websiteBuilder.service';
import CloseIcon from '@mui/icons-material/Close';

const defaultColors = {
  Primary: '#ffffff',
  Secondary: '#ffffff',
  TextPrimary: '#ffffff',
  TextSecondary: '#ffffff',
  BackgroundPrimary: '#ffffff',
  BackgroundSecondary: '#ffffff'
};

const ColorSwatch = ({ color }) => (
  <Box
    sx={{
      width: 50,
      height: 50,
      backgroundColor: color,
      border: '1px solid #ddd',
      borderRadius: 1,
      display: 'inline-block',
      marginRight: 1
    }}
  />
);

ColorSwatch.propTypes = {
  color: PropTypes.string.isRequired
};

const Palettes = () => {
  const [palettes, setPalettes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newPalette, setNewPalette] = useState({
    name: '',
    colors: defaultColors
  });

  useEffect(() => {
    const fetchPalettes = async () => {
      try {
        const response = await WebsiteBuilderService.getAllPalettes();
        if (response.data.success) {
          setPalettes(response.data.palettes);
        }
      } catch (error) {
        console.error('Error fetching palettes:', error);
      }
    };

    fetchPalettes();
  }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setNewPalette({
      name: '',
      colors: defaultColors
    });
  };

  const handleColorChange = (colorKey, value) => {
    setNewPalette((prev) => ({
      ...prev,
      colors: {
        ...prev.colors,
        [colorKey]: value
      }
    }));
  };

  const handleSavePalette = async () => {
    try {
      const response = await WebsiteBuilderService.createPalette(newPalette);
      if (response.data.success) {
        setPalettes([...palettes, response.data.palette]);
        handleCloseModal();
      }
    } catch (error) {
      console.error('Error creating palette:', error);
    }
  };

  const modalStyle = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Color Palettes
        </Typography>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenModal}>
          New Palette
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Palette Name</TableCell>
              <TableCell>Colors</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {palettes.map((palette) => (
              <TableRow key={palette.id}>
                <TableCell>{palette.name}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                    {Object.entries(palette.colors).map(([key, color]) => (
                      <Box key={key} sx={{ textAlign: 'center' }}>
                        <Typography variant="caption" display="block" sx={{ mb: 0.5 }}>
                          {key.replace(/([A-Z])/g, ' $1').trim()}
                        </Typography>
                        <ColorSwatch color={color} />
                        <Typography variant="caption" display="block">
                          {color}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Create New Palette
          </Typography>

          <TextField
            fullWidth
            label="Palette Name"
            value={newPalette.name}
            onChange={(e) => setNewPalette((prev) => ({ ...prev, name: e.target.value }))}
            sx={{ mb: 3 }}
          />

          {Object.entries(newPalette.colors).map(([key, color]) => (
            <Box key={key} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography sx={{ width: 150 }}>{key.replace(/([A-Z])/g, ' $1').trim()}:</Typography>
              <input type="color" value={color} onChange={(e) => handleColorChange(key, e.target.value)} style={{ marginRight: '10px' }} />
              <TextField value={color} onChange={(e) => handleColorChange(key, e.target.value)} sx={{ flexGrow: 1 }} />
            </Box>
          ))}

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={handleSavePalette} disabled={!newPalette.name}>
              Save Palette
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default Palettes;
