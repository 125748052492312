import axios from 'axios';

export const endpoints = {
  api: process.env.REACT_APP_API_URL,
  adminBase: 'admin/',
  palettes: 'palettes/',
  sections: 'sections/'
};

const axiosConfig = {
  withCredentials: true
};

// Palettes
// This is not an admin only endpoint
const getAllPalettes = () => {
  return axios.get(endpoints.api + endpoints.palettes, axiosConfig);
};

const createPalette = (paletteData) => {
  return axios.post(endpoints.api + endpoints.adminBase + endpoints.palettes, paletteData, axiosConfig);
};

// Sections
const getAllSections = () => {
  return axios.get(endpoints.api + endpoints.adminBase + endpoints.sections, axiosConfig);
};

const createSection = (sectionData) => {
  return axios.post(endpoints.api + endpoints.adminBase + endpoints.sections, sectionData, axiosConfig);
};

const WebsiteBuilderService = {
  getAllPalettes,
  createPalette,
  getAllSections,
  createSection
};

export default WebsiteBuilderService;
