import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Grid, Box, CircularProgress, Divider } from '@mui/material';
import PlansService, { Plan } from '../services/plans.service';
import PlanCard from './PlanCard';

const PlanList: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await PlansService.getAll();
        setPlans(response.data.plans);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch plans. Please try again later.');
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const { deprecatedPlans, groupedActivePlans } = useMemo(() => {
    const activePlans = plans.filter((plan) => !plan.deprecated);
    const deprecatedPlans = plans.filter((plan) => plan.deprecated);

    const groupedActivePlans = activePlans.reduce(
      (acc, plan) => {
        if (plan.id === 1) {
          if (!acc['Special']) {
            acc['Special'] = [];
          }
          acc['Special'].push(plan);
        } else {
          if (!acc[plan.language]) {
            acc[plan.language] = [];
          }
          acc[plan.language].push(plan);
        }
        return acc;
      },
      {} as { [key: string]: Plan[] }
    );

    return { deprecatedPlans, groupedActivePlans };
  }, [plans]);

  const handleEditFeatures = (planId: number) => {
    // TODO Implement edit features logic here
    console.log(`Editing features for plan ${planId}`);
  };

  const handleGoToStripe = (stripeProductId: string) => {
    const stripeDomain = process.env.REACT_APP_STRIPE_DOMAIN || 'https://dashboard.stripe.com';
    const url = `${stripeDomain}/products/${stripeProductId}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', color: 'error.main', mt: 4 }}>
        <Typography variant="h6">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Active Plans
      </Typography>
      {Object.entries(groupedActivePlans).map(([language, languagePlans]) => (
        <Box key={language} sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            {language === 'en' ? 'English' : language === 'es' ? 'Spanish' : language}
          </Typography>
          <Grid container spacing={3}>
            {languagePlans.map((plan) => (
              <PlanCard key={plan.id} plan={plan} handleEditFeatures={handleEditFeatures} handleGoToStripe={handleGoToStripe} />
            ))}
          </Grid>
        </Box>
      ))}

      {deprecatedPlans.length > 0 && (
        <>
          <Divider sx={{ my: 4 }} />
          <Typography variant="h4" gutterBottom>
            Deprecated Plans
          </Typography>
          <Grid container spacing={3}>
            {deprecatedPlans.map((plan) => (
              <PlanCard key={plan.id} plan={plan} handleEditFeatures={handleEditFeatures} handleGoToStripe={handleGoToStripe} />
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default PlanList;
