import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  IconButton,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import WebsiteBuilderService from '../../services/websiteBuilder.service';

const Sections = () => {
  const [sections, setSections] = useState([]);
  const [open, setOpen] = useState(false);
  const [newSection, setNewSection] = useState({
    previewURL: '',
    name: '',
    type: '',
    fields: []
  });

  // Add new state for the field form
  const [currentField, setCurrentField] = useState({
    name: '',
    type: 'text',
    canBeHidden: false
  });

  // Modal styles
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
  };

  useEffect(() => {
    fetchSections();
  }, []);

  const fetchSections = () => {
    WebsiteBuilderService.getAllSections().then((response) => {
      if (response.data.success) {
        setSections(response.data.sections);
      }
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSection((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Add new handlers for fields
  const handleFieldInputChange = (e) => {
    const { name, value, checked } = e.target;
    setCurrentField((prev) => ({
      ...prev,
      [name]: name === 'canBeHidden' ? checked : value
    }));
  };

  const addField = () => {
    if (currentField.name) {
      setNewSection((prev) => ({
        ...prev,
        fields: [...prev.fields, { ...currentField }]
      }));
      // Reset current field
      setCurrentField({
        name: '',
        type: 'text',
        canBeHidden: false
      });
    }
  };

  const removeField = (indexToRemove) => {
    setNewSection((prev) => ({
      ...prev,
      fields: prev.fields.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sectionData = {
        ...newSection
      };

      const response = await WebsiteBuilderService.createSection(sectionData);
      if (response.data.success) {
        fetchSections();
        handleClose();
        setNewSection({
          previewURL: '',
          name: '',
          type: '',
          fields: []
        });
      }
    } catch (error) {
      console.error('Error creating section:', error);
    }
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Website Sections
        </Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          New Section
        </Button>
      </Box>

      {/* Updated Modal Form */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
        <Box sx={modalStyle} component="form" onSubmit={handleSubmit}>
          <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Add New Section
          </Typography>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Preview URL"
              name="previewURL"
              value={newSection.previewURL}
              onChange={handleInputChange}
              required
            />
            <TextField fullWidth label="Name" name="name" value={newSection.name} onChange={handleInputChange} required />
            <TextField
              fullWidth
              label="Section Identifier"
              name="section"
              value={newSection.section}
              onChange={handleInputChange}
              required
              placeholder="Should match the component name"
            />
            <FormControl fullWidth required>
              <InputLabel>Type</InputLabel>
              <Select name="type" value={newSection.type} label="Type" onChange={handleInputChange}>
                <MenuItem value="navbar">Navbar</MenuItem>
                <MenuItem value="hero">Hero</MenuItem>
                <MenuItem value="section">Section</MenuItem>
                <MenuItem value="footer">Footer</MenuItem>
              </Select>
            </FormControl>

            {/* Fields Builder Section */}
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Fields
            </Typography>

            {/* Current Fields List */}
            {newSection.fields.map((field, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography flex={1}>
                  {field.name} ({field.type}){field.canBeHidden ? ' - Can be hidden' : ''}
                </Typography>
                <IconButton onClick={() => removeField(index)} size="small">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}

            {/* Add Field Form */}
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
              <TextField size="small" label="Field Name" name="name" value={currentField.name} onChange={handleFieldInputChange} />
              <FormControl size="small">
                <InputLabel>Type</InputLabel>
                <Select name="type" value={currentField.type} label="Type" onChange={handleFieldInputChange} sx={{ minWidth: 100 }}>
                  <MenuItem value="text">Text</MenuItem>
                  <MenuItem value="button">Button</MenuItem>
                  <MenuItem value="image">Image</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={<Checkbox name="canBeHidden" checked={currentField.canBeHidden} onChange={handleFieldInputChange} />}
                label="Can be hidden"
              />
              <IconButton onClick={addField} color="primary">
                <AddIcon />
              </IconButton>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>

      {/* Update the table cell to display fields better */}
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Preview</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Section Identifier</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Available Fields</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sections.map((section) => (
              <TableRow key={section.section}>
                <TableCell>
                  <Box
                    component="img"
                    src={section.previewURL}
                    alt={`${section.name} preview`}
                    sx={{
                      width: 200,
                      height: 'auto',
                      objectFit: 'cover',
                      borderRadius: 1
                    }}
                  />
                </TableCell>
                <TableCell>{section.name}</TableCell>
                <TableCell>{section.section}</TableCell>
                <TableCell>{section.type}</TableCell>
                <TableCell>
                  {section.fields
                    .map((field) =>
                      typeof field === 'string' ? field : `${field.name} (${field.type})${field.canBeHidden ? ' - Can be hidden' : ''}`
                    )
                    .join(', ')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Sections;
