import React from 'react';
import { Grid, Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, CardActions, Button } from '@mui/material';
import { Check, Close, Edit, Launch } from '@mui/icons-material';
import { Plan, Feature } from '../services/plans.service';

// Feature names (you might want to move this to a separate file or fetch from API)
const featureNames: { [key: number]: string } = {
  1: 'Product Management',
  2: 'User Management',
  3: 'Reporting',
  4: 'API Access',
  5: 'Advanced Analytics'
};

interface PlanCardProps {
  plan: Plan;
  handleEditFeatures: (planId: number) => void;
  handleGoToStripe: (stripeProductId: string) => void;
}

const PlanCard: React.FC<PlanCardProps> = ({ plan, handleEditFeatures, handleGoToStripe }) => {
  return (
    <Grid item xs={12} sm={6} md={4} key={plan.id}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {plan.name}
          </Typography>
          <Typography variant="h6" color="primary" gutterBottom>
            ${plan.price} / month
          </Typography>
          <Typography variant="h6" gutterBottom>
            Subscribers count: {plan.accountPlanCount}
          </Typography>
          <List>
            {plan.defaultSpecs.features.map((feature: Feature) => (
              <ListItem key={feature.featureId}>
                <ListItemIcon>{feature.enabled ? <Check color="primary" /> : <Close color="error" />}</ListItemIcon>
                <ListItemText
                  primary={featureNames[feature.featureId]}
                  secondary={feature.volumeLimits?.products ? `Up to ${feature.volumeLimits.products} products` : null}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
        <CardActions>
          <Button startIcon={<Edit />} onClick={() => handleEditFeatures(plan.id)} variant="outlined" color="primary">
            Edit Features
          </Button>
          {plan.stripeProductId && (
            <Button
              startIcon={<Launch />}
              onClick={() => plan.stripeProductId && handleGoToStripe(plan.stripeProductId)}
              variant="outlined"
              color="secondary"
            >
              Stripe Product
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default PlanCard;
